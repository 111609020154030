
import React from "react"

import {Layout} from "../components/layout"
import Seo from "../components/seo"
import {H1, P} from "../components/Typography"
import * as Sections from "../components/SectionElements"

const NotFoundPage = () => (
  <Layout homebutton={true}>
      <Sections.CenteredSection>
        <Seo title="404" />
        <H1>404</H1>
        <P context='bigtext'>Cette page n'existe pas</P>
      </Sections.CenteredSection>
  </Layout>
)

export default NotFoundPage